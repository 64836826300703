
const requireModule = require.context('.', true, /\.js$/);
const modules = {};
function camelize(str) {
    return str.replace(/(?:^\w|[A-Z]|\b\w|\s+)/g, function(match, index) {
      if (+match === 0) return ""; // or if (/\s+/.test(match)) for white spaces
      return index === 0 ? match.toLowerCase() : match.toUpperCase();
    });
  }
// eslint-disable-next-line array-callback-return
requireModule.keys().map((f) => {
  if (f === './index.js') return;
  const moduleName = camelize(f.replace(/(.\/viewer\/|\.\/|\.js)/g, ''));
  modules[moduleName] = requireModule(f).default;
});

export default modules;