import axios from '@/api/index.js';
import { parseJSON, add, format, setDefaultOptions, isToday, isTomorrow } from 'date-fns';
import { ru } from 'date-fns/locale';
const accessToken = 'access_token';
const refreshToken = 'refresh_token';
setDefaultOptions({ locale: ru });

export const saveTokens = tokens => {
    localStorage.setItem(accessToken, tokens.access_token);
    localStorage.setItem(refreshToken, tokens.refresh_token);
    axios.defaults.headers.common.Authorization = `Bearer ${tokens.access_token}`;
};

export const destroyTokens = () => {
    localStorage.removeItem(accessToken);
    localStorage.removeItem(refreshToken);
    axios.defaults.headers.common.Authorization = null;
};

export function formatEventDate(begin_at, end_at) {
    const beginDate = parseJSON(begin_at);
    const endDate = parseJSON(end_at);

    return `${
        isToday(beginDate) ? 'Сегодня' : isTomorrow(beginDate) ? 'Завтра' : format(beginDate, 'dd MMMM')
    } с ${format(beginDate, 'HH:mm')} до ${format(endDate, 'HH:mm')}`;
}

export function formatDateToHours(date) {
    return format(parseJSON(date), 'HH:mm');
}
