import Vue from 'vue';
import VueRouter from 'vue-router';

import AlNavbar from '@/components/AlNavbar';
import AuthService from '@/services/user.js';
import ForgotPassword from '@/views/ForgotPassword';
import ActivitiesList from '@/views/ActivitiesList';

Vue.use(VueRouter);

const routes = [
    {
        path: '/',
        name: 'main',
        props: {
            default: route => ({
                showLegend: route.query.showLegend,
            }),
        },
        components: {
            default: () => import('@/views/Main.vue'),
            header: AlNavbar,
        },
    },

    {
        path: '/event/:eventId',
        name: 'event',
        props: {
            header: route => ({
                isEmpty: true,
                logoSize: 'lg',
                isLogoCentered: true,
            }),
            default: route => ({
                eventId: route.params.eventId,
            }),
        },
        components: {
            default: () => import('@/views/Event/EventOffline.vue'),
            header: AlNavbar,
        },
        meta: { hideBackgroundVideo: true },
    },

    {
        path: '/auth',
        name: 'Authorization',
        components: {
            default: () => import('@/views/Auth.vue'),
            header: AlNavbar,
        },
        props: {
            header: route => ({
                isEmpty: true,
                logoSize: 'md',
            }),
        },
    },

    {
        path: '/register',
        name: 'Registration',
        components: {
            default: () => import('@/views/Register.vue'),
            header: AlNavbar,
        },
        props: {
            header: route => ({
                isEmpty: true,
                logoSize: 'md',
            }),
        },
    },

    {
        path: '/reset_password',
        name: 'ForgotPassword',
        components: {
            default: () => import('@/views/ForgotPassword.vue'),
            header: AlNavbar,
        },
        props: {
            header: route => ({
                isEmpty: true,
                logoSize: 'md',
            }),
        },
    },

    {
        path: '/event-online/:eventId',
        name: 'event-online',
        components: {
            default: () => import('@/views/Event/EventIndex'),
            header: AlNavbar,
        },
        meta: { hideBackgroundVideo: true },
    },
    {
        path: '/events-list/:missionId',
        name: 'events-list',
        props: {
            default: route => ({
                missionId: route.params['missionId'],
            }),
        },
        components: {
            default: () => import('@/views/EventsList.vue'),
            header: AlNavbar,
        },
        meta: { hideBackgroundVideo: true },
    },
    {
        path: '/help',
        name: 'Help',
        components: {
            default: () => import('@/views/Help.vue'),
            header: AlNavbar,
        },
    },

    {
        path: '/ratings',
        name: 'Ratings',
        components: {
            default: () => import('@/views/Ratings.vue'),
            header: AlNavbar,
        },
    },
    {
        path: '/activities',
        name: 'Activities List',
        components: {
            default: ActivitiesList,
            header: AlNavbar,
        },
    },
    {
        path: '/legend',
        name: 'Legend',
        components: {
            default: () => import('@/views/Legend.vue'),
            header: AlNavbar,
        },
    },
    {
        path: '/get-score',
        name: 'Get Points',
        components: {
            default: () => import('@/views/GetPoints.vue'),
            header: AlNavbar,
        },
    },
    {
        path: '*',
        redirect: '/',
    },
];

const router = new VueRouter({
    mode: 'history',
    base: process.env.BASE_URL,
    routes,
});

const routesWithoutAuth = ['Authorization', 'Registration', 'ForgotPassword'];

router.beforeEach(async (to, from, next) => {
    window.scrollTo(0, 0);
    if (routesWithoutAuth.includes(to.name)) {
        const isAuth = await AuthService.isAuth();
        if (isAuth)
            next({
                name: 'main',
            });
        else next();
        return next();
    } else {
        await AuthService.checkAuth(next, to);
    }
});

export default router;
