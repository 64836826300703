import axios from 'axios';

class MissionApi {
    async getAllMissions() {
        try {
            const result = await axios.get('/missions');
            return result.data;
        } catch (err) {
            console.log(err);
            alert(`Ошибка запроса ${err.message}`);
        }
    }

    async getAllMissionsInfo() {
        try {
            const result = await axios.get('/missions/info');
            return result.data;
        } catch (err) {
            console.log(err);
            alert(`Ошибка запроса ${err.message}`);
        }
    }

    async getMissionById(missionId) {
        try {
            const result = await axios.get(`/missions/${missionId}`);
            return [null, result.data];
        } catch (err) {
            return [err, null];
        }
    }
}

export default new MissionApi();
