<template>
  <b-sidebar
      :id="sidebarId"
      class="al-mobile-sidebar"
      bg-variant="dark"
      :visible="visible"
      @change="sidebarChangeHandler"
  >
    <slot/>
  </b-sidebar>
</template>
<script>
export default {
  name: 'AlMobileSidebar',
  props: {
    sidebarId: {type: String, required: true},
    visible: {type: Boolean, required: false, default: false}
  },

  methods: {
    sidebarChangeHandler(visible) {
      this.$emit('change', visible);
    }
  }
}
</script>
