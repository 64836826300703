<template>
    <b-button
        :variant="variant"
        :size="size"
        :to="to"
        class="al-btn text-decoration-none"
        :href="(!to && href) || ''"
        :target="target"
        @click="$emit('click')"
    >
        <span class="al-btn__icon-left" v-if="iconLeft">
            <slot name="icon" />
        </span>

        <span class="al-btn__main">
            <slot name="default" />
        </span>

        <span class="al-btn__icon-right" v-if="iconRight || points">
            <slot name="icon" v-if="!points" />
          <al-bonus-badge :count="points" v-else />
        </span>
    </b-button>
</template>
<script>
import AlBonusBadge from "@/components/AlBonusBadge";
export default {
    name: 'AlBtn',
  components: {AlBonusBadge},
  props: {
        variant: { type: String, default: 'primary' },
        to: { type: [String, Object], required: false },
        size: { type: String, default: 'lg' },
        iconLeft: { type: Boolean, default: false },
        iconRight: { type: Boolean, default: false },
        href: { type: String, required: false },
        target: { type: String, required: false },
        points: {type: String | Number}
    },
};
</script>
