import MissionApi from '@/services/mission.js';

export default {
    namespaced: true,

    state: {
        missions: null,
    },

    actions: {
        async fetchMissions({ commit }) {
            const data = await MissionApi.getAllMissions();
            commit('SET_MISSIONS', data);
            return data;
        },
    },

    mutations: {
        SET_MISSIONS(state, missions) {
            state.missions = missions;
        },
    },

    getters: {
        missionsWithoutSecret(state) {
            return state.missions?.filter(i => i.title !== 'Секретная миссия').sort((a, b) => a.id - b.id) || [];
        },
    },
};
