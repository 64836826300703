<template>
  <b-container class="forgot-password-page__container">
  <div class="forgot-password-page">

    <div class="auth-text">
      <div class="title">Цифровые 
        <span>
          <AlfaLogoIcon />
          генты:</span
        >
        Секретная служба
      </div>
      <div class="text">Специальная программа подготовки агентов цифрового мышления Альфа-Банка</div>
    </div>
    <al-card class="auth-form forgot-password-page__card" key="auth">
      <div class="forgot-password-page__wrapper">
      <div class="form-title">Восстановить пароль</div>
      <h3 class="text-center" v-if="isResetPasswordLinkSent">Ссылка для восстановления пароля отправлена на ваш e-mail</h3>
      <b-form @submit="sendResetPasswordLink" v-if="!token && !isResetPasswordLinkSent" key="reset-password-form">
        <b-form-group id="resetPassword">
          <b-form-input
              name="login"
              @change.native="onChangeResetPasswordForm"
              placeholder="Email"
              :state="$v.formResetPassword.login.$error ? false : null"
          ></b-form-input>

          <div class="error-title" v-if="getFirstErrorResetPassword || serverError">{{ errorText || serverError }}</div>
          <b-button variant="primary" type="submit">Восстановить пароль</b-button>
        </b-form-group>
      </b-form>
      <b-form @submit="setNewPassword" v-if="token && !isResetPasswordLinkSent" key="set-new-password-form">
        <b-form-group id="newPassword">

          <al-password-input
              name="password"
              @change.native="onChangeNewPasswordForm"
              placeholder="Новый пароль"
              :state="$v.formNewPassword.password.$error ? false : null"
              type="password"
          />

          <al-password-input
              name="password_repeat"
              @change.native="onChangeNewPasswordForm"
              placeholder="Подтвердите пароль"
              :state="$v.formNewPassword.password_repeat.$error ? false : null"
              type="password"
          />

          <div class="error-title" >{{ errorText || serverError }}</div>
          <b-button variant="primary" type="submit">Сменить пароль</b-button>
        </b-form-group>
      </b-form>
      </div>
    </al-card>
  </div>
  </b-container>
</template>
<script>
import AlCard from "@/components/AlCard";
import {helpers, required, sameAs} from "vuelidate/lib/validators";
import AuthService from "@/services/user";
import AlPasswordInput from "@/components/AlPasswordInput";
import AlfaLogoIcon from '@/assets/images/icons/AlfaLogoIcon.svg';
const passwordMatch = helpers.regex('passwordMatch', /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d).{8,}$/);
export default {
  name: 'ForgotPassword',
  components: { AlCard, AlPasswordInput, AlfaLogoIcon },
  data: () => ({
    isResetPasswordLinkSent: false,
    formResetPassword: {
      login: '',
    },
    formNewPassword: {
      password: '',
      password_repeat: ''
    },
    token: '',
    serverError: '',
  }),
  validations: {
    formResetPassword: {
      login: {
        required,
      },
    },
    formNewPassword: {
      password: {
        required,
        passwordMatch,
      },
      password_repeat: {
        required,
        sameAsPassword: sameAs('password'),
        passwordMatch,
      }
    },
  },
  computed: {
    getFirstErrorResetPassword() {
      if (this.$v.$anyError) {
        return Object.keys(this.$v.formResetPassword).find(x => this.$v.formResetPassword[x].$error === true);
      }
    },
    getFirstErrorNewPassword() {
      if (this.$v.$anyError) {
        return Object.keys(this.$v.formNewPassword).find(x => this.$v.formNewPassword[x].$error === true);
      }
    },
    errorText() {
      if (this.getFirstErrorResetPassword === 'login') return 'Введите логин';
      if (this.getFirstErrorNewPassword === 'password_repeat') return 'Пароли не совпадают';
      if (this.getFirstErrorNewPassword === 'password')
        return 'Пароль должен содержать не менее 8-ми символов, минимум 1 заглавную, строчную латинскую букву, цифру';
    },
  },

  mounted(){
    if(this.$route.query['token']){
      this.token = this.$route.query['token'];
    }
  },

  methods: {
    onChangeResetPasswordForm(e) {
      this.formResetPassword[e.target.name] = e.target.value;
    },
    onChangeNewPasswordForm(e) {
      this.formNewPassword[e.target.name] = e.target.value;
    },
    async sendResetPasswordLink(event) {
      event.preventDefault();
      this.$v.formResetPassword.$touch();
      if (this.$v.formResetPassword.$anyError) {
        return;
      }
      this.serverError = '';
      try {
        await AuthService.sendResetPasswordLink(this.formResetPassword.login);
        this.isResetPasswordLinkSent = true;
      } catch (error){
        if (error?.response.status === 400) {
          this.serverError = 'Неверный логин';
          return;
        }

        if(error?.response.status === 404) {
          this.serverError = 'Пользователь с таким e-mail не зарегистрирован'
        }
      }

    },
    async setNewPassword(event){
      event.preventDefault();
      this.$v.formNewPassword.$touch();
      if (this.$v.formNewPassword.$anyError) {
        return;
      }
      this.serverError = '';

      const [err, result] = await AuthService.resetPassword(this.token, this.formNewPassword.password);

      if(err) {
        this.serverError = 'Пароль уже был восстановлен по этой ссылке';
        this.isResetPasswordLinkSent = false;
        this.token = null;
        return;
      }



      this.$router.push({name: 'Authorization'})


    }

  },
}
</script>
