<template>
  <b-card
      no-body
      class="al-card"
      :class="{
    'p-0': noPadding,
    'safari': isSafari,
    'overflow-hidden': overflowHidden
      }"
  >
    <slot/>
  </b-card>
</template>
<script>
export default {
  name: 'AlCard',
  props: {
    title: {type: String, required: false},
    noPadding: {type: Boolean, required: false},
    overflowHidden: {type: Boolean, required: false, default: false}
  },
  computed: {
      isSafari: () => /^((?!chrome|android).)*safari/i.test(navigator.userAgent)
  }
}
</script>
