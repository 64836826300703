<template>
  <div class="al-bonus-badge">
    <div class="al-bonus-badge__count">
      +{{count}}
    </div>
    <div class="al-bonus-badge__icon">
      <bytes-icon/>
    </div>
  </div>
</template>
<script>
import BytesIcon from "@/assets/images/icons/bytes.svg";

export default {
  name: 'AlBonusBadge',
  components: {BytesIcon},
  props: {
    count: {type: Number, required: true}
  }
}
</script>
