import axios from 'axios';
import { destroyTokens, saveTokens } from '@/utils/helpers';

export const accessToken = 'access_token';
export const refreshToken = 'refresh_token';

axios.defaults.baseURL = process.env.VUE_APP_BACKEND_HOST;
const access_token = localStorage.getItem(accessToken);


if (access_token) {
    axios.defaults.headers.common.Authorization = `Bearer ${access_token}`;
}
  
  const createAxiosResponseInterceptor = () => {
    const interceptor = axios.interceptors.response.use(
      (response) => response,
      (error) => {
        // Reject promise if usual error
        if (error.response?.status !== 401) {
          return Promise.reject(error);
        }
  
        const refresh_token = localStorage.getItem(refreshToken);
        if (!refresh_token) {
          destroyTokens();
          // eslint-disable-next-line no-restricted-globals
          const noAuthRoutes = ['/auth', '/register', '/reset_password'];
          if (!noAuthRoutes.includes(location.pathname)) {
            location.replace(`/auth?from=${encodeURIComponent(location.pathname)}`);
          }
          return Promise.reject(error);
        }
  
        /*
         * When response code is 401, try to refresh the token.
         * Eject the interceptor so it doesn't loop in case
         * token refresh causes the 401 response
         */
        axios.interceptors.response.eject(interceptor);
  
        return axios
          .post('/users/refresh', null, { headers: { 'Authorization': `Bearer ${refresh_token}` }})
          .then(({ data: tokens }) => {
            saveTokens(tokens);
          })
          .catch(() => {
            destroyTokens();
            location.replace(`/auth?from=${encodeURIComponent(location.pathname)}`);
            return Promise.reject(error);
          })
          .finally(createAxiosResponseInterceptor);
      },
    );
  };
  
  axios.interceptors.request.use(
    (config) => ({
      ...config,
      withCredentials: true,
    }),
    (error) => Promise.reject(error),
  );
  
  createAxiosResponseInterceptor();
  
  export default axios;