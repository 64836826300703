import Vue from 'vue';
import Vuex from 'vuex';
import Vuelidate from 'vuelidate';
import App from './App.vue';
import router from './router';
import VueMq from 'vue-mq';

import store from './store';

import { BootstrapVue, BootstrapVueIcons } from 'bootstrap-vue';
import VueYandexMetrika from 'vue-yandex-metrika';
import VideoBackground from 'vue-responsive-video-background-player';
import VueYouTubeEmbed from 'vue-youtube-embed';

Vue.use(VueYouTubeEmbed);

Vue.config.productionTip = false;

Vue.use(Vuex);
Vue.use(BootstrapVue);
Vue.use(BootstrapVueIcons);
Vue.use(Vuelidate);
Vue.use(VueMq, {
    breakpoints: {
        xs: 450,
        sm: 955,
        md: 1030,
        lg: 1310,
        xl: 1876,
        xxl: Infinity,
    },
    defaultBreakpoint: 'lg',
});

if (process.env.VUE_APP_ENABLE_YM) {
    Vue.use(VueYandexMetrika, {
        id: process.env.VUE_APP_ENABLE_YM,
        router: router,
        env: process.env.NODE_ENV,
        options: {
            clickmap: true,
            trackLinks: true,
            accurateTrackBounce: true,
            webvisor: true,
        },
    });
}
Vue.component('VideoBackground', VideoBackground);

new Vue({
    router,
    store,
    render: h => h(App),
}).$mount('#app');
