<template>
  <div class="al-password-input" :class="{
    'al-password-input--text': inputType === 'text'
  }">
  <b-input
      :type="inputType"
      :placeholder="placeholder"
      @blur="$emit('blur', $event)"
      :state="state"
      :name="name"
  />
    <b-button
        variant="link"
        v-b-tooltip.hover
        :title="inputType === 'password' ? 'Показать пароль' : 'Скрыть пароль'"
        class="al-password-input__button"
        @click="changeInputType"
    >
      <b-icon-eye
          v-show="inputType === 'password'"
          class="al-password-input__icon"
      />

      <b-icon-eye-slash
          v-show="inputType === 'text'"
          class="al-password-input__icon"
      />
    </b-button>

  </div>

</template>
<script>
export default {
  name: 'AlPasswordInput',

  props: {
    modelValue: {type: String},
    placeholder: {type: String},
    state: {type: Object | String},
    name: {type: String}
  },

  data(){
    return {
      inputType: 'password'
    }
  },

  methods: {
    changeInputType(){
      if(this.inputType === 'password') {
        return this.inputType = 'text';
      }

      if(this.inputType === 'text') {
        return this.inputType = 'password'
      }
    }
  }
}
</script>
