<template>
    <svg viewBox="0 0 359 229" fill="none" xmlns="http://www.w3.org/2000/svg" :style="logoSizes">
        <path
            d="M65.7599 0L39.8527 139.06L0 173.822L87.4364 156.263L263.869 229L359 37.0536L65.7599 0Z"
            fill="#E30521"
        />
        <path
            d="M188.74 91.0064C188.2 90.1513 186.796 88.0484 183.098 88.0418C177.936 88.0352 174.488 92.5778 174.482 99.1077C174.475 105.532 177.831 110.022 183.075 110.028C186.763 110.032 188.187 107.77 188.766 106.783L188.763 109.527L194.073 109.533L194.105 79.7523L188.756 79.7457L188.74 91.0064ZM184.351 92.8023C187.483 92.8056 188.815 95.889 188.812 99.1242C188.809 102.772 187.148 105.317 184.338 105.314C181.532 105.311 179.949 102.822 179.953 99.1143C179.959 95.2815 181.588 92.799 184.351 92.8023Z"
            fill="white"
        />
        <path
            d="M243.025 88.1112C238.903 88.1079 237.228 90.6862 236.551 91.977C235.248 89.5109 233.103 88.098 230.112 88.0947C227.237 88.0914 225.484 89.6298 224.592 91.0724L224.595 88.9827L219.246 88.9761L219.223 109.563L224.572 109.569L224.586 97.5165C224.589 94.6873 225.832 92.931 228.352 92.9343C230.882 92.9376 232.228 94.6543 232.228 97.5264L232.215 109.579L237.607 109.586L237.62 97.533C237.623 94.7038 238.883 92.9476 241.426 92.9509C243.956 92.9542 245.305 94.6708 245.301 97.5429L245.288 109.596L250.68 109.603L250.693 96.612C250.697 91.5313 247.795 88.1145 243.025 88.1112Z"
            fill="white"
        />
        <path
            d="M267.593 89.0322L262.579 102.218L257.556 89.0223L251.578 89.0157L259.865 109.302L256.519 117.955L261.935 117.961L273.044 89.0388L267.593 89.0322Z"
            fill="white"
        />
        <path
            d="M123.986 84.6613C123.187 82.2645 122.265 80.3696 119.104 80.3663C115.939 80.363 114.956 82.248 114.11 84.6514L105.389 109.437L111.153 109.444L113.166 103.551L124.259 103.564L126.115 109.461L132.243 109.467L123.986 84.6613ZM114.85 98.5729L118.805 86.8236H118.949L122.657 98.5795L114.85 98.5729Z"
            fill="white"
        />
        <path
            d="M171.372 103.175L171.379 96.252C171.386 91.1978 168.175 88.0087 162.773 88.0021C156.828 87.9955 154.005 91.8217 153.966 95.1131L159.253 95.1197C159.351 94.268 160.141 92.7989 162.77 92.8022C164.812 92.8055 166.125 93.7563 166.122 96.2487L160.904 96.2421C156.233 96.2355 153.456 98.6884 153.45 102.789C153.446 107.074 156.476 110.008 160.852 110.012C164.059 110.015 165.842 108.496 166.622 107.344C167.313 108.737 168.846 109.507 170.853 109.51L172.678 109.513L172.685 104.753C171.734 104.75 171.372 104.31 171.372 103.175ZM166.119 101.815C166.115 104.195 164.434 105.548 162.246 105.545C160.348 105.542 158.851 104.845 158.855 102.795C158.858 100.781 160.5 100.451 161.924 100.455L166.122 100.458L166.119 101.815Z"
            fill="white"
        />
        <path d="M104.796 117.402L104.789 123.262L132.884 123.293L132.891 117.433L104.796 117.402Z" fill="white" />
        <path
            d="M206.958 110.058C200.895 110.051 196.829 105.628 196.836 99.0516C196.842 92.4754 200.764 88.0616 206.59 88.0682C212.459 88.0748 216.249 92.3797 216.242 99.0318V100.158L202.395 100.141C202.639 103.261 204.373 105.185 206.965 105.189C209.646 105.192 210.715 103.218 210.715 103.218L216.052 103.224C215.736 105.826 213.423 110.065 206.958 110.058ZM210.617 96.4337C210.337 94.8226 209.36 92.5778 206.587 92.5745C204.188 92.5712 202.892 94.1492 202.468 96.4238L210.617 96.4337Z"
            fill="white"
        />
        <path
            d="M146.37 102.67C145.653 104.314 144.196 105.179 142.39 105.179C139.787 105.176 137.965 102.627 137.968 98.979C137.971 95.275 139.745 92.7858 142.36 92.7891C144.709 92.7924 145.837 94.278 146.245 95.1033L151.552 95.1099C151.262 92.9971 149.14 87.9858 142.367 87.9759C136.698 87.9693 132.579 92.5944 132.57 98.9691C132.563 105.347 136.688 109.979 142.383 109.986C149.348 109.992 151.371 104.75 151.562 102.729L151.575 102.607L146.4 102.601L146.37 102.67Z"
            fill="white"
        />
    </svg>
</template>
<script>
export default {
    name: 'AlLogo',

    props: {
        size: { type: String, default: 'md' },
    },

    computed: {
        logoSizes() {
            switch (this.size) {
                case 'sm':
                    return { height: `44px` };
                case 'md':
                    return { height: `68px` };
                case 'lg':
                    return { height: `120px` };
            }
        },
    },
};
</script>
