import AuthService from '@/services/user.js';

export default {
  namespaced: true,

  state: {
    isAuthenticated: false,
    user: null,
  },

  actions: {
    registration(_, registerInput) {
      return AuthService.register(registerInput);
    },
    authorization(_, loginInput) {
      return AuthService.login(loginInput);
    },
  },

  mutations: {
    SET_AUTHENTICATED(state, { isAuthenticated, user = null }) {
      state.isAuthenticated = isAuthenticated;
      state.user = user;
    },

  },

  getters: {
    isAuthenticated: ({ isAuthenticated }) => isAuthenticated,
    getUser: ({ user }) => user,
    getEmail: ({ user }) => user?.email ?? '',
    getFirstName: ({ user }) => user?.first_name ?? '',
    getLastName: ({ user }) => user?.last_name ?? '',
    getFullName: ({ user }) => `${user?.first_name || ''} ${user?.last_name || ''}`,
  },
};
