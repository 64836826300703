import axios from '@/api/index.js';
import { destroyTokens, saveTokens } from '@/utils/helpers';
import store from '@/store';

class AuthService {
    register = registerInput => {
        delete axios.defaults.headers.common.Authorization;
        return axios.post('users/register', registerInput, { headers: { 'Content-Type': 'multipart/form-data' } });
    };

    // login = (loginInput) => axios.post('auth/signin', loginInput);
    login = loginInput => {
        delete axios.defaults.headers.common.Authorization;
        return axios
            .post('users/login', loginInput, { headers: { 'Content-Type': 'multipart/form-data' } })
            .then(({ data: tokens }) => {
                saveTokens(tokens);
            });
    };


    logout = () => {
        store.commit('auth/SET_AUTHENTICATED', { isAuthenticated: false });
        destroyTokens();
        return false;
    };

    // forgotPassword = ({ email, locale }) => axios.post('auth/forgot_password', { email }, { headers: { locale } });

    // resetPassword = ({ password, token }) => axios.patch('auth/reset_password', { password, token });

    // confirmEmail = () => axios.patch('auth/confirm_email');

    // sendConfirmEmail = (locale) => axios.post('auth/send_confirm_email', null, { headers: { locale } });

    checkAuth = async (next, to) => {
        if (store.getters['auth/isAuthenticated']) {
            next();
        } else {
            try {
                const { data: User } = await axios.get('users/whoami');

                if (User) {
                    store.commit('auth/SET_AUTHENTICATED', {
                        isAuthenticated: true,
                        user: User,
                    });
                    next();
                } else {
                    next({
                        name: 'Authorization',
                        query: {
                            from: to.fullPath,
                        },
                    });
                }
            } catch (err) {
                next({
                    name: 'Authorization',
                    query: {
                        from: to.fullPath,
                    },
                });
            }
        }
    };

    isAuth = async () => {
        if (store.getters['auth/isAuthenticated']) {
            return true;
        }
        return axios
            .get('users/whoami')
            .then(({ data: user }) => {
                store.commit('auth/SET_AUTHENTICATED', {
                    isAuthenticated: !!user,
                    user,
                });
                return true;
            })
            .catch(() => {
                store.commit('auth/SET_AUTHENTICATED', {
                    isAuthenticated: false,
                });
                return false;
            });
    };

    whoAmI = () =>
        axios
            .get('users/whoami')
            .then(({ data: user }) => {
                store.commit('auth/SET_AUTHENTICATED', {
                    isAuthenticated: !!user,
                    user,
                });
                return true;
            })
            .catch(console.log);

    async sendResetPasswordLink(email){
        return await axios.get(`/users/reset_password?email=${email}`);
    }

    async resetPassword (token, newPassword) {
        try {
            const setNewPasswordInput = new FormData();
            setNewPasswordInput.append('password', newPassword.trim());
            setNewPasswordInput.append('token', token);

            const result = await axios.post(`/users/reset_password`, setNewPasswordInput, { headers: { 'Content-Type': 'multipart/form-data' } });
            return [null, result];

        }catch (err){
            return [err, null];
        }
    }
}

export default new AuthService();
