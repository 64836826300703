<template>
    <b-navbar
        class="al-navbar"
        :class="{
            'al-navbar--dark': isSidebarOpened,
            'al-navbar--blur': scrollTopOffset > blurOffsetTreshold,
        }"
        type="dark"
        sticky
    >
        <b-container>
            <b-navbar-brand
                to="/"
                :class="{
                    'mx-auto': isLogoCentered || isMobileAuth,
                }"
            >
                <al-logo :size="logoSizeResponsive" />
            </b-navbar-brand>

            <b-navbar-toggle target="nav-collapse" v-if="!isEmpty"></b-navbar-toggle>

            <b-collapse id="nav-collapse" is-nav v-if="!isEmpty">
                <b-navbar-nav class="al-navbar__items" v-if="$device === 'desktop'">
                    <b-nav-item
                        :to="navItem.to"
                        class="al-navbar__item"
                        v-for="(navItem, index) in navItems"
                        :key="`navbar-item-${index}`"
                        >{{ navItem.title }}</b-nav-item
                    >
                </b-navbar-nav>

                <!-- Right aligned nav items -->
                <b-navbar-nav class="ml-auto d-inline-flex align-items-center">
                    <div class="al-navbar__buttons" v-if="$device !== 'mobile'">
                        <al-btn
                            href="https://t.me/+Z8p2KlKDCMc5NzYy"
                            target="_blank"
                            variant="outline-primary"
                            size="lg"
                            class="al-navbar__button"
                            icon-right
                        >
                            Общий канал связи
                            <template #icon>
                                <b-icon-telegram />
                            </template>
                        </al-btn>

                        <al-btn
                            href="https://t.me/+okdH5TqnHhs3ZDRi"
                            target="_blank"
                            variant="primary"
                            class="al-navbar__button"
                            icon-right
                        >
                            Секретная линия
                            <template #icon>
                                <b-icon-telegram />
                            </template>
                        </al-btn>
                    </div>
                    <div class="al-navbar__profile">
                        <al-btn
                            variant="link"
                            size="sm"
                            class="al-navbar__profile-item"
                            icon-left
                            v-if="$device === 'mobile' ? isSidebarOpened === true : true"
                        >
                            <template #icon>
                                <profile-icon />
                            </template>
                            {{ getFullName }}
                        </al-btn>
                        <al-btn
                            variant="link"
                            size="sm"
                            class="text-button-2 al-navbar__profile-item"
                            v-if="$device === 'desktop'"
                            @click="logout"
                            >Выход</al-btn
                        >
                        <al-btn
                            v-b-toggle:mobile-nav
                            variant="link"
                            size="sm"
                            class="al-navbar__navbar-toggler"
                            icon-right
                            v-if="$device !== 'desktop'"
                        >
                            <div class="">Меню</div>
                            <template #icon>
                                <svg
                                    width="53"
                                    height="26"
                                    viewBox="0 0 53 26"
                                    fill="none"
                                    xmlns="http://www.w3.org/2000/svg"
                                    v-show="!isSidebarOpened"
                                >
                                    <rect width="53" height="4" rx="2" fill="white" />
                                    <rect y="11" width="53" height="4" rx="2" fill="white" />
                                    <rect y="22" width="53" height="4" rx="2" fill="white" />
                                </svg>

                                <svg
                                    width="41"
                                    height="42"
                                    viewBox="0 0 41 42"
                                    fill="none"
                                    xmlns="http://www.w3.org/2000/svg"
                                    v-show="isSidebarOpened"
                                >
                                    <rect
                                        x="3.17578"
                                        y="0.847656"
                                        width="53"
                                        height="4"
                                        rx="2"
                                        transform="rotate(45 3.17578 0.847656)"
                                        fill="white"
                                    />
                                    <rect
                                        x="0.347656"
                                        y="38.3242"
                                        width="53"
                                        height="4"
                                        rx="2"
                                        transform="rotate(-45 0.347656 38.3242)"
                                        fill="white"
                                    />
                                </svg>
                            </template>
                        </al-btn>
                    </div>
                </b-navbar-nav>
            </b-collapse>
        </b-container>

        <al-mobile-sidebar sidebar-id="mobile-nav" @change="setSidebar" v-if="$device !== 'desktop'">
            <b-nav vertical>
                <b-nav-item
                    :active="navItem.active"
                    :to="navItem.to"
                    class="al-mobile-sidebar__item"
                    v-for="(navItem, index) in navItems"
                    :key="`mobile-navbar-item-${index}`"
                    @click="hideSidebar(navItem)"
                >
                    {{ navItem.title }}
                </b-nav-item>
            </b-nav>
            <h1 class="al-mobile-sidebar__item logout" @click="logout">Выход</h1>
            <div class="al-navbar__mobile-buttons" v-if="$device === 'mobile'">
                <div class="mt-2">
                    <al-btn
                        variant="primary"
                        class="al-navbar__button"
                        href="https://t.me/+okdH5TqnHhs3ZDRi"
                        target="_blank"
                        icon-right
                    >
                        Секретная линия
                        <template #icon>
                            <b-icon-telegram />
                        </template>
                    </al-btn>
                </div>
                <div class="mt-2">
                    <al-btn
                        variant="outline-primary"
                        href="https://t.me/+Z8p2KlKDCMc5NzYy"
                        target="_blank"
                        size="lg"
                        class="al-navbar__button"
                        icon-right
                    >
                        Общий канал связи
                        <template #icon>
                            <b-icon-telegram />
                        </template>
                    </al-btn>
                </div>
            </div>
        </al-mobile-sidebar>
    </b-navbar>
</template>
<script>
import AlLogo from '@/components/AlLogo';
import AlBtn from '@/components/AlBtn';
import ProfileIcon from '@/components/icons/ProfileIcon';
import AlMobileSidebar from '@/components/AlMobileSidebar';
import DeviceMixin from '@/mixins/deviceMixin';
import AuthService from '@/services/user.js';
import { mapGetters } from 'vuex';

export default {
    name: 'AlNavbar',
    components: { AlMobileSidebar, ProfileIcon, AlBtn, AlLogo },
    mixins: [DeviceMixin],
    props: {
        isEmpty: { type: Boolean, default: false, required: false },
        logoSize: { type: String, default: 'md' },
        isLogoCentered: { type: Boolean, default: false, required: false },
    },
    data() {
        return {
            isSidebarOpened: false,
            scrollTopOffset: 0,
        };
    },
    computed: {
        ...mapGetters('auth', ['getFullName']),
        navItems() {
            return [
                {
                    title: 'Штаб',
                    to: '/',
                },

                {
                    title: 'Доска почёта',
                    to: '/ratings',
                },
                {
                    title: 'Легенда',
                    to: '/legend',
                },
                //
                // {
                //   title: 'Наша миссия',
                //   to: '/about',
                // },
                //
                {
                    title: 'Инструкция',
                    to: '/help',
                },
                {
                    title: 'Репозиторий',
                    to: '/activities',
                },
            ];
        },
        logoSizeResponsive() {
            return this.$mq === 'sm' ? 'sm' : this.logoSize;
        },
        isMobileAuth() {
            return this.$mq !== 'xl' && (this.$route.name === 'Registration' || this.$route.name === 'Authorization');
        },

        blurOffsetTreshold() {
            return 10;
        },
    },

    mounted() {
        window.addEventListener('scroll', evt => {
            this.scrollTopOffset = window.scrollY;
        });
    },

    methods: {
        setSidebar(visible) {
            this.isSidebarOpened = visible;
        },
        async logout() {
            AuthService.logout();
            await this.$router.replace('/auth');
        },
        hideSidebar(item) {
            if (item.to === this.$route.path && this.isSidebarOpened)
                this.$root.$emit('bv::toggle::collapse', 'mobile-nav');
        },
    },
};
</script>
