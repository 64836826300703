<template>
    <div id="app">
        <VideoBackground
            :src="require('@/assets/video/background.webm')"
            style="width: 100%; max-height: 100vh; height: 100%; position: fixed"
            v-if="!hideBackgroundVideo"
            overlay="linear-gradient(104.63deg, rgba(0, 0, 0, 0.8) 0.49%, rgba(0, 0, 0, 0) 223.11%)"
        />
        <router-view name="header" />
        <transition name="fade-page" mode="out-in">
            <router-view name="default" class="page" />
        </transition>
    </div>
</template>

<script>
// import VideoBackground from 'video-background'

export default {
    name: 'App',
    methods: {
        heightMobileFix() {
            const vh = window.innerHeight * 0.01;
            document.documentElement.style.setProperty('--vh', `${vh}px`);
        },
    },
    created() {
        window.addEventListener('resize', this.heightMobileFix);
    },
    mounted() {
        this.heightMobileFix();
    },
    beforeDestroy() {
        window.removeEventListener('resize', this.heightMobileFix);
    },

    computed: {
        hideBackgroundVideo() {
            return this.$route.meta.hideBackgroundVideo;
        },
    },
};
</script>

<style lang="scss">
@import '@/assets/scss/_index.scss';
#app {
    height: calc(var(--vh, 1vh) * 100);
    display: flex;
    flex-direction: column;
}
</style>
