export default {
    computed: {
        $device(){
            if( this.$mq === 'lg' ){
                return 'tablet';
            }

            if( this.$mq === 'xl' || this.$mq === 'xxl' ){
                return 'desktop';
            }

            if( this.$mq === 'md' ){
                return 'tablet';
            }

            if( this.$mq === 'sm' ){
                return 'mobile';
            }

            if( this.$mq === 'xs' ){
                return 'mobile';
            }

        },
    }
}
