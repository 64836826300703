<template>
    <b-container class="activities">
        <div class="activities-list-title">Таблица активностей</div>
        <div class="activities-list">
            <div class="activities-list-table">
                <b-table :items="getActivities" :fields="fields" borderless :striped="$device !== 'mobile'" table-variant="dark"></b-table>
            </div>
        </div>
    </b-container>
</template>

<script>
import AlCard from '@/components/AlCard';
import DeviceMixin from "@/mixins/deviceMixin";

export default {
    name: 'ActivitiesList',
    components: { AlCard },
    mixins: [DeviceMixin],
    data: () => ({
        fields: [
            { key: 'name', label: 'Активность' },
            { key: 'description', label: 'Описание' },
            { key: 'points', label: 'Байты' },
        ],
    }),
    computed: {
        getActivities() {
            return [
                { name: 'Идентификация', description: 'Пройти регистрацию на платформе', points: 16 },
                { name: 'Анализ майндсета', description: 'Пройти тест после регистрации на оценку цифрового майндсета', points: 32 },
                { name: 'Взяться за задание', description: 'Записаться на мероприятие', points: 8 },
                { name: 'Отправиться на задание', description: 'Прийти на мероприятие', points: 32 },
                { name: 'Проанализировать прошедшее задание', description: 'Просмотр записи мероприятия', points: 16 },
                { name: 'Закончить задание', description: 'Остаться до конца мероприятия', points: 64 },
                { name: 'Пройти брифинг задания', description: 'Прочитать анонс мероприятия', points: 4 },
                { name: 'Выполнить дешифровку на задании', description: 'Пройти тест во время мероприятия', points: 64 },
                { name: 'Проявить личную активность', description: 'Задать интересный вопрос', points: 16 },
                { name: 'Выполнить дешифровку после задания', description: 'Пройти тест на записи трансляции', points: 64 },
                { name: 'Пройти тест для аналитической группы', description: 'Пройти экстра тест (только для дальних регионов РФ)', points: 64 },
                { name: 'Выполнить поручение', description: 'Выполнить домашнее задание', points: 64 },
                { name: 'Скачать дополнительные данные', description: 'Скачать полезные материалы на записи трансляции', points: 16 },
            ];
        },
    },
};
</script>
